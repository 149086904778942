
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46url_93uusEXYbUH8Meta } from "/app/pages/dominternet/[...city]/[...url].vue?macro=true";
import { default as _91slug_93AdxfBucbirMeta } from "/app/pages/dominternet/[...city]/address/[slug].vue?macro=true";
import { default as indexSv7qAC87t6Meta } from "/app/pages/dominternet/[...city]/for-house/index.vue?macro=true";
import { default as indexzlWBklXPXJMeta } from "/app/pages/dominternet/[...city]/providers-not-found/index.vue?macro=true";
import { default as indexxqjAu8ElTNMeta } from "/app/pages/dominternet/[...city]/providers-to-address/index.vue?macro=true";
import { default as indexE4wXPrJZWNMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/[smartFilter]/index.vue?macro=true";
import { default as indexZVweNy6FcXMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/index.vue?macro=true";
import { default as indexygyrDiBKeJMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/reviews/index.vue?macro=true";
import { default as _91id_934FNkLvbsoBMeta } from "/app/pages/dominternet/[...city]/providers/[provider]/tariffs/[id].vue?macro=true";
import { default as indexbHN3wOUL05Meta } from "/app/pages/dominternet/[...city]/providers/index.vue?macro=true";
import { default as indexaIMo3QxqgEMeta } from "/app/pages/dominternet/[...city]/rating/index.vue?macro=true";
import { default as indexfOi7HDef3BMeta } from "/app/pages/dominternet/[...city]/reviews/add/index.vue?macro=true";
import { default as index7qWPGtcjfSMeta } from "/app/pages/dominternet/[...city]/reviews/index.vue?macro=true";
import { default as indexlAeY2CcrReMeta } from "/app/pages/dominternet/[...city]/streets/[street]/index.vue?macro=true";
import { default as index4OtJ56H1NeMeta } from "/app/pages/dominternet/[...city]/streets/index.vue?macro=true";
import { default as _91smartFilter_93x3TXACFMHNMeta } from "/app/pages/dominternet/[...city]/tariffs/[smartFilter].vue?macro=true";
import { default as indexzfqIPrCpndMeta } from "/app/pages/dominternet/[...city]/tariffs/index.vue?macro=true";
import { default as indexHHRZLPSSUWMeta } from "/app/pages/dominternet/[...city]/test-content/index.vue?macro=true";
import { default as indexGCiLC3bohmMeta } from "/app/pages/dominternet/about/[...query]/index.vue?macro=true";
import { default as indexMOSYbtHtmFMeta } from "/app/pages/dominternet/about/index.vue?macro=true";
import { default as _91article_93YFQR3AB8nuMeta } from "/app/pages/dominternet/blog/[article].vue?macro=true";
import { default as _91author_93kDl13QdjtyMeta } from "/app/pages/dominternet/blog/author/[author].vue?macro=true";
import { default as _91slug_93kdjYdpK4daMeta } from "/app/pages/dominternet/blog/category/[slug].vue?macro=true";
import { default as indexGFH5xXENDAMeta } from "/app/pages/dominternet/blog/index.vue?macro=true";
import { default as indexhK8epqZyDMMeta } from "/app/pages/dominternet/career/index.vue?macro=true";
import { default as indexiOhoqX5C1AMeta } from "/app/pages/dominternet/compare/index.vue?macro=true";
import { default as index0k84oPRsIeMeta } from "/app/pages/dominternet/confidentiality/[...query]/index.vue?macro=true";
import { default as indexRajqn6qybPMeta } from "/app/pages/dominternet/confidentiality/index.vue?macro=true";
import { default as indexMENu5QKdI4Meta } from "/app/pages/dominternet/contacts/[...query]/index.vue?macro=true";
import { default as indexOJ8g0egbFBMeta } from "/app/pages/dominternet/contacts/index.vue?macro=true";
import { default as indexu7rTmJ2uYRMeta } from "/app/pages/dominternet/downdetector/[slug]/[category]/index.vue?macro=true";
import { default as indexMkRmcYnWHpMeta } from "/app/pages/dominternet/downdetector/[slug]/index.vue?macro=true";
import { default as indexbprp4MdFg9Meta } from "/app/pages/dominternet/downdetector/index.vue?macro=true";
import { default as indexVIaETPNChqMeta } from "/app/pages/dominternet/health/index.vue?macro=true";
import { default as indexLS5LnBRguFMeta } from "/app/pages/dominternet/help/[...query]/index.vue?macro=true";
import { default as indexXwnYKQV8hQMeta } from "/app/pages/dominternet/help/index.vue?macro=true";
import { default as indexJy3QoHGcG9Meta } from "/app/pages/dominternet/index.vue?macro=true";
import { default as indexUz410nWhLdMeta } from "/app/pages/dominternet/info/[...query]/index.vue?macro=true";
import { default as indexZBrxopibL6Meta } from "/app/pages/dominternet/info/index.vue?macro=true";
import { default as indexm15ghnOsKDMeta } from "/app/pages/dominternet/legal/[...query]/index.vue?macro=true";
import { default as indexrfiWqgBC4AMeta } from "/app/pages/dominternet/legal/index.vue?macro=true";
import { default as indexReQnNR1I6EMeta } from "/app/pages/dominternet/locations/index.vue?macro=true";
import { default as indexGlJ9F0XRSUMeta } from "/app/pages/dominternet/personal/address/[...slug]/index.vue?macro=true";
import { default as indexHiKx9wwe47Meta } from "/app/pages/dominternet/personal/checkout/index.vue?macro=true";
import { default as index1eJv26e5mMMeta } from "/app/pages/dominternet/personal/complete/index.vue?macro=true";
import { default as _91order_93LQeJw6cz1xMeta } from "/app/pages/dominternet/personal/order/[order].vue?macro=true";
import { default as indexHgTEYCSJCzMeta } from "/app/pages/dominternet/personal/order/index.vue?macro=true";
import { default as index419uLAsBk9Meta } from "/app/pages/dominternet/personal/quiz-results/index.vue?macro=true";
import { default as indexOFx6xt9T58Meta } from "/app/pages/dominternet/providers-to-address/index.vue?macro=true";
import { default as indexppuwBOtFGsMeta } from "/app/pages/dominternet/providers/[provider]/checkaddress/index.vue?macro=true";
import { default as indexq26rJpohm7Meta } from "/app/pages/dominternet/providers/[provider]/index.vue?macro=true";
import { default as indexBbMdoHEUeBMeta } from "/app/pages/dominternet/providers/[provider]/reviews/index.vue?macro=true";
import { default as thanksZFyJs9WGBLMeta } from "/app/pages/dominternet/providers/[provider]/thanks.vue?macro=true";
import { default as indexwpA8eFMKDHMeta } from "/app/pages/dominternet/providers/index.vue?macro=true";
import { default as quiz_45completeUQksBHilOlMeta } from "/app/pages/dominternet/quiz-complete.vue?macro=true";
import { default as indexRkbAM29tQnMeta } from "/app/pages/dominternet/rating/index.vue?macro=true";
import { default as indexm0KDXEsCvgMeta } from "/app/pages/dominternet/speedtest/index.vue?macro=true";
import { default as indexZfnKySpzwcMeta } from "/app/pages/dominternet/speedtest/rt/index.vue?macro=true";
import { default as testXpsNg7RL89Meta } from "/app/pages/dominternet/speedtest/rt/test.vue?macro=true";
import { default as indexPu1QBdazIiMeta } from "/app/pages/dominternet/subscriptions/[slug]/index.vue?macro=true";
import { default as indexQvluqhYvQLMeta } from "/app/pages/dominternet/subscriptions/index.vue?macro=true";
export default [
  {
    name: "city-url",
    path: "/:city(.*)*/:url(.*)*",
    meta: _91_46_46_46url_93uusEXYbUH8Meta || {},
    component: () => import("/app/pages/dominternet/[...city]/[...url].vue")
  },
  {
    name: "city-address-slug",
    path: "/:city(.*)*/address/:slug()",
    meta: _91slug_93AdxfBucbirMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/address/[slug].vue")
  },
  {
    name: "city-for-house",
    path: "/:city(.*)*/for-house",
    component: () => import("/app/pages/dominternet/[...city]/for-house/index.vue")
  },
  {
    name: "city-providers-not-found",
    path: "/:city(.*)*/providers-not-found",
    component: () => import("/app/pages/dominternet/[...city]/providers-not-found/index.vue")
  },
  {
    name: "city-providers-to-address",
    path: "/:city(.*)*/providers-to-address",
    meta: indexxqjAu8ElTNMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/providers-to-address/index.vue")
  },
  {
    name: "city-providers-provider-smartFilter",
    path: "/:city(.*)*/providers/:provider()/:smartFilter()",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/[smartFilter]/index.vue")
  },
  {
    name: "city-providers-provider",
    path: "/:city(.*)*/providers/:provider()",
    meta: indexZVweNy6FcXMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/index.vue")
  },
  {
    name: "city-providers-provider-reviews",
    path: "/:city(.*)*/providers/:provider()/reviews",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/reviews/index.vue")
  },
  {
    name: "city-providers-provider-tariffs-id",
    path: "/:city(.*)*/providers/:provider()/tariffs/:id()",
    component: () => import("/app/pages/dominternet/[...city]/providers/[provider]/tariffs/[id].vue")
  },
  {
    name: "city-providers",
    path: "/:city(.*)*/providers",
    component: () => import("/app/pages/dominternet/[...city]/providers/index.vue")
  },
  {
    name: "city-rating",
    path: "/:city(.*)*/rating",
    component: () => import("/app/pages/dominternet/[...city]/rating/index.vue")
  },
  {
    name: "city-reviews-add",
    path: "/:city(.*)*/reviews/add",
    component: () => import("/app/pages/dominternet/[...city]/reviews/add/index.vue")
  },
  {
    name: "city-reviews",
    path: "/:city(.*)*/reviews",
    component: () => import("/app/pages/dominternet/[...city]/reviews/index.vue")
  },
  {
    name: "city-streets-street",
    path: "/:city(.*)*/streets/:street()",
    component: () => import("/app/pages/dominternet/[...city]/streets/[street]/index.vue")
  },
  {
    name: "city-streets",
    path: "/:city(.*)*/streets",
    component: () => import("/app/pages/dominternet/[...city]/streets/index.vue")
  },
  {
    name: "city-tariffs-smartFilter",
    path: "/:city(.*)*/tariffs/:smartFilter()",
    meta: _91smartFilter_93x3TXACFMHNMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/tariffs/[smartFilter].vue")
  },
  {
    name: "city-tariffs",
    path: "/:city(.*)*/tariffs",
    meta: indexzfqIPrCpndMeta || {},
    component: () => import("/app/pages/dominternet/[...city]/tariffs/index.vue")
  },
  {
    name: "city-test-content",
    path: "/:city(.*)*/test-content",
    component: () => import("/app/pages/dominternet/[...city]/test-content/index.vue")
  },
  {
    name: "about-query",
    path: "/about/:query(.*)*",
    component: () => import("/app/pages/dominternet/about/[...query]/index.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/dominternet/about/index.vue")
  },
  {
    name: "blog-article",
    path: "/blog/:article()",
    component: () => import("/app/pages/dominternet/blog/[article].vue")
  },
  {
    name: "blog-author-author",
    path: "/blog/author/:author()",
    component: () => import("/app/pages/dominternet/blog/author/[author].vue")
  },
  {
    name: "blog-category-slug",
    path: "/blog/category/:slug()",
    component: () => import("/app/pages/dominternet/blog/category/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/dominternet/blog/index.vue")
  },
  {
    name: "career",
    path: "/career",
    meta: indexhK8epqZyDMMeta || {},
    component: () => import("/app/pages/dominternet/career/index.vue")
  },
  {
    name: "compare",
    path: "/compare",
    component: () => import("/app/pages/dominternet/compare/index.vue")
  },
  {
    name: "confidentiality-query",
    path: "/confidentiality/:query(.*)*",
    component: () => import("/app/pages/dominternet/confidentiality/[...query]/index.vue")
  },
  {
    name: "confidentiality",
    path: "/confidentiality",
    component: () => import("/app/pages/dominternet/confidentiality/index.vue")
  },
  {
    name: "contacts-query",
    path: "/contacts/:query(.*)*",
    component: () => import("/app/pages/dominternet/contacts/[...query]/index.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/app/pages/dominternet/contacts/index.vue")
  },
  {
    name: "downdetector-slug-category",
    path: "/downdetector/:slug()/:category()",
    component: () => import("/app/pages/dominternet/downdetector/[slug]/[category]/index.vue")
  },
  {
    name: "downdetector-slug",
    path: "/downdetector/:slug()",
    component: () => import("/app/pages/dominternet/downdetector/[slug]/index.vue")
  },
  {
    name: "downdetector",
    path: "/downdetector",
    component: () => import("/app/pages/dominternet/downdetector/index.vue")
  },
  {
    name: "health",
    path: "/health",
    meta: indexVIaETPNChqMeta || {},
    component: () => import("/app/pages/dominternet/health/index.vue")
  },
  {
    name: "help-query",
    path: "/help/:query(.*)*",
    component: () => import("/app/pages/dominternet/help/[...query]/index.vue")
  },
  {
    name: "help",
    path: "/help",
    meta: indexXwnYKQV8hQMeta || {},
    component: () => import("/app/pages/dominternet/help/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/dominternet/index.vue")
  },
  {
    name: "info-query",
    path: "/info/:query(.*)*",
    component: () => import("/app/pages/dominternet/info/[...query]/index.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/app/pages/dominternet/info/index.vue")
  },
  {
    name: "legal-query",
    path: "/legal/:query(.*)*",
    component: () => import("/app/pages/dominternet/legal/[...query]/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/dominternet/legal/index.vue")
  },
  {
    name: "locations",
    path: "/locations",
    component: () => import("/app/pages/dominternet/locations/index.vue")
  },
  {
    name: "personal-address-slug",
    path: "/personal/address/:slug(.*)*",
    component: () => import("/app/pages/dominternet/personal/address/[...slug]/index.vue")
  },
  {
    name: "personal-checkout",
    path: "/personal/checkout",
    meta: indexHiKx9wwe47Meta || {},
    component: () => import("/app/pages/dominternet/personal/checkout/index.vue")
  },
  {
    name: "personal-complete",
    path: "/personal/complete",
    meta: index1eJv26e5mMMeta || {},
    component: () => import("/app/pages/dominternet/personal/complete/index.vue")
  },
  {
    name: "personal-order-order",
    path: "/personal/order/:order()",
    meta: _91order_93LQeJw6cz1xMeta || {},
    component: () => import("/app/pages/dominternet/personal/order/[order].vue")
  },
  {
    name: "personal-order",
    path: "/personal/order",
    meta: indexHgTEYCSJCzMeta || {},
    component: () => import("/app/pages/dominternet/personal/order/index.vue")
  },
  {
    name: "personal-quiz-results",
    path: "/personal/quiz-results",
    meta: index419uLAsBk9Meta || {},
    component: () => import("/app/pages/dominternet/personal/quiz-results/index.vue")
  },
  {
    name: "providers-to-address",
    path: "/providers-to-address",
    component: () => import("/app/pages/dominternet/providers-to-address/index.vue")
  },
  {
    name: "providers-provider-checkaddress",
    path: "/providers/:provider()/checkaddress",
    meta: indexppuwBOtFGsMeta || {},
    component: () => import("/app/pages/dominternet/providers/[provider]/checkaddress/index.vue")
  },
  {
    name: "providers-provider",
    path: "/providers/:provider()",
    component: () => import("/app/pages/dominternet/providers/[provider]/index.vue")
  },
  {
    name: "providers-provider-reviews",
    path: "/providers/:provider()/reviews",
    component: () => import("/app/pages/dominternet/providers/[provider]/reviews/index.vue")
  },
  {
    name: "providers-provider-thanks",
    path: "/providers/:provider()/thanks",
    component: () => import("/app/pages/dominternet/providers/[provider]/thanks.vue")
  },
  {
    name: "providers",
    path: "/providers",
    component: () => import("/app/pages/dominternet/providers/index.vue")
  },
  {
    name: "quiz-complete",
    path: "/quiz-complete",
    meta: quiz_45completeUQksBHilOlMeta || {},
    component: () => import("/app/pages/dominternet/quiz-complete.vue")
  },
  {
    name: "rating",
    path: "/rating",
    component: () => import("/app/pages/dominternet/rating/index.vue")
  },
  {
    name: "speedtest",
    path: "/speedtest",
    component: () => import("/app/pages/dominternet/speedtest/index.vue")
  },
  {
    name: "speedtest-rt",
    path: "/speedtest/rt",
    component: () => import("/app/pages/dominternet/speedtest/rt/index.vue")
  },
  {
    name: "speedtest-rt-test",
    path: "/speedtest/rt/test",
    component: () => import("/app/pages/dominternet/speedtest/rt/test.vue")
  },
  {
    name: "subscriptions-slug",
    path: "/subscriptions/:slug()",
    component: () => import("/app/pages/dominternet/subscriptions/[slug]/index.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/app/pages/dominternet/subscriptions/index.vue")
  }
]