<template>
  <header
    class="hdr-p"
    :class="{ 'light': headerLight, 'dark': !headerLight, 'hdr-p__black': $route.name === 'speedtest' || $route.name === 'speedtest-rt-test' }"
  >
    <div class="hdr-p__top container d-flex align-center">
      <component
        :is="$route.name === 'index' ? 'div' : 'a'"
        :href="$route.name === 'index' ? undefined : '/'"
        class="logo"
        @click.prevent="$router.push('/')"
      >
        <img
          v-if="headerLight"
          loading="lazy"
          src="~/assets/images/logo.svg"
          alt="logo"
        >
        <img
          v-else
          loading="lazy"
          src="~/assets/images/logo-white.svg"
          alt="logo"
        >
        {{
          houseUrl || needGetCity
            ? "ДомИнтернет"
            : `Домашний интернет ${getAddressText.cityOnlyLoc}`
        }}
      </component>
      <div class="hdr-p__location d-flex">
        <template v-if="needGetCity">
          <ClientOnly>
            <button
              class="hdr-p__city d-flex align-center subhead-3"
              @click="showDialogCity"
            >
              <span
                v-if="!houseUrl"
                class="relative hdr-p__city-pin d-flex"
              />
              <span class="text-no-wrap">{{ getAddressText.fullAddress }}</span>
            </button>
          </ClientOnly>
        </template>
        <button
          v-else
          class="hdr-p__city d-flex align-center subhead-3"
          @click="showDialogCity"
        >
          <span
            v-if="!houseUrl"
            class="relative hdr-p__city-pin d-flex"
          />
          <span class="text-no-wrap">{{ getAddressText.fullAddress }}</span>
        </button>
        <button
          v-if="!houseUrl"
          class="hdr-p__address d-flex align-center"
          @click="showDialogAddress"
        >
          <span class="relative hdr-p__address-pin d-flex" />
          Укажите адрес
        </button>
      </div>
      <nav
        v-if="$route.name !== 'locations'"
        class="hdr-p__navtop"
      >
        <ul class="d-flex">
          <li
            v-for="link in listTopMenu"
            :key="link.link"
            class="hdr-p__navtop-item"
          >
            <component
              :is="link.button || needGetCity ? 'button' : NuxtLink"
              :to="link.button || needGetCity ? undefined : link.link"
              class="hdr-p__navtop-link subhead-3"
              :class="{ active: link.routeName.includes($route.name), confirm: link.target === 'order' }"
              @click.prevent="$router.push(link.link)"
            >
              <span
                v-if="link.icon"
                class="hdr-p__navtop-icon d-inline-flex"
                :class="link.target"
              />
              <span
                v-if="link.target === 'compare' && compareListTariffs.length"
                class="hdr-p__navtop-compare d-flex"
              >{{ compareListTariffs.length }}</span>
              {{ link.name }}
            </component>
          </li>
        </ul>
      </nav>
    </div>
    <div
      v-if="$route.name !== 'locations'"
      class="hdr-p__bottom container d-flex align-center"
    >
      <nav class="hdr-p__navbottom">
        <ul class="d-flex">
          <li
            v-for="link in listBottomMenu.filter(el => !needGetCity ? el : el.target !== 'for-house')"
            :key="link.link"
            class="hdr-p__navbottom-item"
          >
            <component
              :is="link.button || needGetCity ? 'button' : NuxtLink"
              :to="link.button || needGetCity ? undefined : link.link"
              class="hdr-p__navbottom-link title-5"
              :class="{ active: link.routeName.includes($route.name) }"
              @click.prevent="$router.push(link.link)"
            >
              <span
                v-if="link.providers"
                class="hdr-p__navbottom-providers"
              >{{ link.providers }}</span>
              <span
                v-if="link.icon"
                class="hdr-p__navbottom-icon d-inline-flex"
              />
              {{ link.name }}
            </component>
          </li>
        </ul>
      </nav>
      <a
        v-if="!segmentationActive && !hidePhoneNumber && !hideNumber"
        :href="callNumber.call_number ? 'tel:' + callNumber.call_number : undefined"
        class="hdr-p__phone subhead-2"
      >{{ callNumber.display_number }}</a>
    </div>
  </header>
  <ClientOnly>
    <VAppBar
      v-if="$route.name !== 'compare' && $route.name !== 'locations'"
      order="1"
      tag="div"
      scroll-behavior="hide inverted"
      class="hdr-p__fixed hdr-p light"
    >
      <div class="hdr-p__fixed-wrap container d-flex align-center">
        <component
          :is="$route.name === 'index' ? 'div' : 'a'"
          :href="$route.name === 'index' ? undefined : '/'"
          class="logo"
          @click.prevent="$router.push('/')"
        >
          <img
            loading="lazy"
            src="~/assets/images/logo.svg"
            alt="logo"
          >
          {{
            houseUrl
              ? "ДомИнтернет"
              : `Домашний интернет ${getAddressText.cityOnlyLoc}`
          }}
        </component>
        <div class="hdr-p__location d-flex">
          <button
            class="hdr-p__city d-flex align-center subhead-3"
            @click="showDialogCity"
          >
            <span
              v-if="!houseUrl"
              class="relative hdr-p__city-pin d-flex"
            />
            <template v-if="needGetCity">
              <ClientOnly>
                <span class="text-no-wrap">{{ getAddressText.fullAddress }}</span>
              </ClientOnly>
            </template>
            <span
              v-else
              class="text-no-wrap"
            >{{ getAddressText.fullAddress }}</span>
          </button>
          <button
            v-if="!houseUrl"
            class="hdr-p__fixed-loc d-flex align-center"
            @click="showDialogAddress"
          >
            <span class="relative hdr-p__fixed-loc-pin d-flex" />
            Укажите адрес
          </button>
        </div>
        <div class="hdr-p__fixed-actions d-flex align-center">
          <button
            v-if="houseUrl"
            class="hdr-p__fixed-address"
            @click="$router.push('/personal/address/${houseUrl}/')"
          >
            <span class="hdr-p__fixed-img d-flex" /><span
              v-if="providersCount"
              class="hdr-p__fixed-providers"
            >{{ providersCount }}</span>
          </button>
          <button
            class="hdr-p__fixed-compare"
            @click="$router.push('/compare/')"
          >
            <span class="img d-flex" />
            <span
              v-if="compareListTariffs.length"
              class="count"
            >{{ compareListTariffs.length }}</span>
          </button>
          <button
            v-if="hasOrder"
            class="hdr-p__fixed-order"
            @click="$router.push('/personal/order/')"
          >
            <span class="d-flex" />
          </button>
          <a
            v-if="!segmentationActive && !hidePhoneNumber && !hideNumber"
            :href="callNumber.call_number ? 'tel:' + callNumber.call_number : undefined"
            class="hdr-p__phone title-4"
          >{{ callNumber.display_number }}</a>
          <LazyVMenu
            v-model:model-value="burgerMenu"
            content-class="white--bg"
            location="bottom end"
            width="250"
            scroll-strategy="close"
          >
            <template #activator="{ props }">
              <button
                v-bind="props"
                :class="{ active: props['aria-expanded'] === 'true' }"
                aria-label="mobile menu"
                class="hdr-p__fixed-burger"
              >
                <span />
              </button>
            </template>
            <LazyVList
              elevation="4"
            >
              <LazyVListItem
                v-for="link in [...listBottomMenu, ...listTopMenu.filter(el => !el.icon)]"
                :to="link.link"
                :class="{ active: link.routeName.includes($route.name) }"
              >
                {{ link.name }}
              </LazyVListItem>
            </LazyVList>
          </LazyVMenu>
        </div>
      </div>
    </VAppBar>
  </ClientOnly>
</template>

<script setup lang="ts">
import { VAppBar } from 'vuetify/components/VAppBar'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'
import { NuxtLink } from '#components'
import { providersComposible } from '~/composible/getProviders'
import { setCompare } from '~/composible/setFavourites'

const ctx = useNuxtApp()
const $route = useRoute()
const cityStore = useCities()
const mainStore = useMainStore()
const getAddressText = computed(() => cityStore.getAddressText)
const houseUrl = computed(() => cityStore.getHouseUrl)
const needGetCity = computed(() => cityStore.getNeedGetCity)
const currentCity = computed(() => cityStore.getCity)
const callNumber = computed(() => mainStore.getCallNumber?.connect)
const segmentationActive = computed(() => mainStore.getSegmentationActive)
const hideNumber = computed(() => mainStore.getHideNumber)
const hidePhoneNumber = computed(() => $route.name === 'speedtest' || $route.name?.toString()?.startsWith('downdetector'))
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)
const userInfo = computed(() => mainStore.getUserAuth)
const hasOrder = computed(() => mainStore.getUserAuth?.interactionOrdersCount)
const indexTopologies = computed(() => cityStore.getIndexTopologies)
const { fetchProviders } = providersComposible()
const { compareListTariffs } = setCompare()
const burgerMenu = ref(false)
const headerLight = computed(() => {
  return (
    $route.name !== 'city-url'
    && $route.name !== 'city-for-house'
    && $route.name !== 'city-streets-street'
    && $route.name !== 'city-providers-to-address'
    && $route.name !== 'providers-to-address'
    && $route.name !== 'index'
    && $route.name !== 'speedtest'
    && $route.name !== 'speedtest-rt-test'
  )
})
const client = ctx._apolloClients.default
const sessionid = useCookie('sessionid')

const listTopMenu = computed(() => {
  const arr = [
    {
      name: 'О проекте',
      link: '/about/',
      target: 'about',
      button: $route.path !== '/',
      routeName: ['about'],
    },
    {
      name: 'Подписки',
      link: '/subscriptions/',
      target: 'subscriptions',
      button: $route.path !== '/',
      routeName: ['subscriptions', 'subscriptions-slug'],
    },
    {
      name: 'Блог',
      link: '/blog/',
      target: 'blog',
      routeName: ['blog', 'blog-category-slug', 'blog-article'],
    },
    {
      name: 'Отзывы',
      link: `/${currentCity.value?.url}/reviews/`,
      target: 'reviews',
      button: !indexTopologies.value.includes('/{city}/reviews/'),
      routeName: ['city-reviews', 'city-reviews-add'],
    },
    {
      name: 'Cравнение',
      link: '/compare/',
      icon: true,
      target: 'compare',
      button: true,
      routeName: ['compare'],
    },
  ]
  const userInfoMenu = hasOrder.value
    ? [
        {
          name: 'Моя заявка',
          link: `/personal/order/`,
          icon: true,
          target: 'order',
          button: true,
          routeName: ['personal-order', 'personal-order-order'],
        },
      ]
    : []
  return [...arr, ...userInfoMenu]
})
const listBottomMenu = computed(() => {
  const cityArr = houseUrl.value
    ? [
        {
          name: 'Мой адрес',
          link: `/personal/address/${houseUrl.value}/`,
          target: 'personal-address',
          icon: true,
          providers: ['personal-address', 'personal-address-slug'].includes($route.name) ? 0 : providersCount.value,
          routeName: ['personal-address', 'personal-address-slug'],
        },
      ]
    : [
        {
          name: 'Подключить интернет',
          link: `/${currentCity.value?.url}/`,
          target: 'main-city',
          button: !indexTopologies.value.includes('/{city}/'),
          routeName: ['city-url'],
        },
        {
          name: 'Поиск по адресу',
          link: `/${currentCity.value?.url}/providers-to-address/`,
          target: 'search-by-address',
          button: !indexTopologies.value.includes('/{city}/providers-to-address/'),
          routeName: ['city-providers-to-address'],
        }]
  const mainArr = [
    {
      name: 'Провайдеры',
      link: `/${currentCity.value?.url}/providers/`,
      target: 'providers',
      button: !indexTopologies.value.includes('/{city}/providers/'),
      routeName: ['city-providers', 'city-providers-provider', 'city-providers-provider-reviews', 'city-providers-provider-smartFilter', 'providers-provider', 'providers-provider-checkaddress', 'providers-provider-reviews'],
    },
    {
      name: 'Тарифы',
      link: `/${currentCity.value?.url}/tariffs/`,
      target: 'tariffs',
      button: !indexTopologies.value.includes('/{city}/tariffs/'),
      routeName: ['city-tariffs', 'city-tariffs-smartFilter'],
    },
    {
      name: 'Рейтинг',
      link: `/${currentCity.value?.url}/rating/`,
      target: 'rating',
      button: !indexTopologies.value.includes('/{city}/rating/'),
      routeName: ['city-rating'],
    },
  ]
  const housesArr = availableHouses.value
    ? [
        {
          name: 'Частный дом',
          link: `/${currentCity.value?.url}/for-house/`,
          target: 'for-house',
          button: !indexTopologies.value.includes('/{city}/for-house/'),
          routeName: ['city-for-house'],
        }]
    : []
  return [...cityArr, ...mainArr, ...housesArr]
})

const showDialogCity = () => {
  if (houseUrl.value) showDialogAddress()
  else ctx.$event('cityDialog', { redirect: true, label: 'header' })
}
const showDialogAddress = () => {
  ctx.$event('addressDialog', { redirect: true, label: 'header' })
}

const availableHouses = computed(() => data.value?.houseTypes?.includes('HOUSE'))
const providersCount = computed(() => data.value?.providers?.length)

const fetchHouseTypes = async () => {
  if (currentCity.value?.fias_id) {
    const res = await client.query({
      query: gql`
        query getHouseTypes($input: LocationInfoInput!) {
          location {
            locationTerms(input: $input) {
              tariffsAgg {
                availableHouseTypes
              }
            }
          }
        }
      `,
      variables: {
        input: {
          addrObjFiasId: currentCity.value?.fias_id,
        },
      },
      context: {
        headers: getCommonHeaders.value,
      },
      fetchPolicy: 'no-cache',
    })

    return res.data.location.locationTerms.tariffsAgg?.availableHouseTypes
  }
}

let errorStatus
let errorMessage
const { data, error } = await useAsyncData('header' + (currentCity.value?.fias_id || '') + (houseUrl.value || ''), async () => {
  let providers: any
  let houseTypes: any

  const providersHouseFetch = async () => {
    providers = await fetchProviders()
  }

  const houseTypesFetch = async () => {
    houseTypes = await fetchHouseTypes()
  }

  if (houseUrl.value)
    await Promise.all([providersHouseFetch(), houseTypesFetch()])
  else await Promise.all([houseTypesFetch()])

  return {
    providers,
    houseTypes,
  }
})

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}
watch(
  () => houseUrl.value,
  async () => {
    if (houseUrl.value && data.value)
      data.value.providers = await fetchProviders()
    else if (data.value) data.value.providers = undefined
  },
)
const userInfoAsyncFetch = async () => {
  if (sessionid.value && !userInfo.value?.interactionOrdersCount) {
    const auth = (
      await client.query({
        query: gql`
          mutation Mutation {
            auth {
              currentUserInfo {
                lastName
                firstName
                siteUserId
                verified
                avatarUrl
                interactionOrdersCount
              }
            }
          }
        `,
        context: {
          headers: getCommonHeaders.value,
        },
        fetchPolicy: 'no-cache',
      })
    ).data

    mainStore.$patch({
      userAuth: auth.auth.currentUserInfo,
    })
    if (!auth.auth?.currentUserInfo?.interactionOrdersCount)
      setTimeout(() => userInfoAsyncFetch, 60000)
  }
}
onMounted(() => {
  userInfoAsyncFetch()
})
</script>

<style scoped lang="scss">
.hdr-p {

  &.dark {
    background: color(gradient);
    color: color(gray-light);
    border-bottom: 1px solid rgba(color(white), 0.15);
    .hdr-p__navtop-link, .hdr-p__navbottom-link {
      &:hover, &.active {
        color: color(white);
      }
    }
    .hdr-p__address {
      &:hover {
        color: color(white);
      }
    }
  }
  &.light {
    background: color(white);
    color: color(black);
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15);
    position: relative;
    z-index: 1;
    .hdr-p__navtop-link, .hdr-p__navbottom-link {
      transition: color .24s;
      &:hover, &.active {
        color: color(primary);
      }
    }
    .hdr-p__address {
      color: color(primary);
    }
    .hdr-p__city {
      background: color(gray-p);
      border: 1px solid color(gray-darker);
      transition: unset;
    }
  }

  &__black {
    background: #141526 !important;
  }
  &__top {
    height: 64px;
  }
  &__navtop {
    margin-left: auto;

    &-item {
      margin-left: 24px;
    }
    &-link {
      color: currentColor;
      transition: color .24s;
      position: relative;

      &.active {
        color: color(primary);
      }
      &.confirm {
        position: relative;
        &:after {
      content: "";
      background: url("@/assets/images/useful/ischeckedWhite.svg") 50% 50%
        no-repeat;
      background-size: contain;
      width: 12px;
      height: 12px;
      position: absolute;
      transform: translate(-50%, -50%);
      top: 16px;
      left: 21px;
      z-index: 1;
    }
        &:before {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: color(red);
          position: absolute;
          transform: translate(-50%, -50%);
          top: 16px;
          left: 21px;
          z-index: 1;
        }
      }
    }
    &-icon {
      margin-top: -5px;
      margin-bottom: -8px;
      width: 24px;
      height: 24px;
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
      &.compare {
        mask-image: url('@/assets/images/svg/compare-menu.svg');
      }
      &.order {
        mask-image: url('@/assets/images/svg/user.svg');
        position: relative;
      }
    }
    &-compare {
      position: absolute;
      background: color(primary);
      color: color(white);
      border-radius: 12px;
      font-size: fontSize(small);
      left: 5px;
      line-height: 11px;
      top: -4px;
      z-index:1;
      padding: 2px 6px 1.6px;
      border: 1px solid #fff;
    }
  }
  &__bottom {
    height: 37px;
  }
  &__navbottom {
    margin-right: auto;

    &-item {
      margin-right: 24px;
    }

    &-link {
      color: currentColor;
      transition: color .24s;
      position: relative;

      &.active {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -9px;
          height: 1px;
          background: currentColor;
        }
      }
    }

    &-providers {
      font-size: fontSize(small);
      line-height: 11px;
      background: color(primary);
      border: 1px solid color(white);
      color: color(white);
      position: absolute;
      left: 11px;
      top: -6px;
      padding: 2px 6px 2px;
      border-radius: 12px;
      z-index: 1;
    }

    &-icon {
      margin-top: -5px;
      margin-bottom: -6px;
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/house.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }
  &__location {
    margin-left: 24px;
  }
  &__address {
    position: relative;
    background-color: transparent;
    border: 1px solid currentColor;
    box-shadow: none;
    color: currentColor;
    transition: color .24s;
    margin-left: 12px;
    padding: 7px 15px;
    border-radius: 28px;

    &-pin {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/location.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }
  &__city {
    padding: 6px 15px;
    background: rgba(color(white), 0.1);
    border-radius: 28px;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    min-height: 36px;
    &:hover {
      transition: background .24s;
      background: rgba(color(white), 0.15);
    }

    &-pin {
      margin-right: 4px;
      width: 24px;
      height: 24px;
      mask-image: url('@/assets/images/svg/pin-thin.svg');
      background-color: currentColor;
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: 24px;
    }
  }

  &__phone {
    color: currentColor;
    transition: color .24s;

    &:hover {
      color: color(primary);
    }
  }

  &__fixed {
    background: color(white) !important;
    box-shadow: 0px 0px 18px rgba(3, 5, 32, 0.15) !important;

    &-actions {
      margin-left: auto;
    }

    &-compare {
      width: 32px;
      height: 32px;
        margin-right: 24px;
      position: relative;
      .img {
        width: 32px;
        height: 32px;
        mask-image: url('@/assets/images/svg/compare-menu.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 32px;
        transition: color .24s;
      }
      &:hover .img {
        color: color(primary);
      }
      .count {
        background: color(primary);
        border: 1px solid #fff;
        border-radius: 12px;
        color: #fff;
        font-size: 10px;
        left: 14px;
        line-height: 11px;
        padding: 2px 6px 1.6px;
        position: absolute;
        top: -4px;
        z-index: 1;
      }
    }
    &-loc {
      position: relative;
      background-color: color(primary);
      border: 1px solid transparent;
      color: color(white);
      transition: color .24s;
      margin-left: 12px;
      padding: 7px 15px;
      border-radius: 28px;

      &-pin {
        margin-right: 4px;
        width: 24px;
        height: 24px;
        mask-image: url('@/assets/images/svg/location.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 24px;
      }
    }
    &-address {
      width: 32px;
      height: 32px;
      color: currentColor;
      margin-right: 24px;
      position: relative;
      .hdr-p__fixed-img {
        width: 32px;
        height: 32px;
        mask-image: url('@/assets/images/svg/house.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 32px;
        transition: color .24s;

      }

      &:hover .hdr-p__fixed-img {
        color: color(primary);
      }
    }
    &-providers {
      font-size: fontSize(small);
      line-height: 11px;
      background: color(primary);
      border: 1px solid color(white);
      color: color(white);
      position: absolute;
      left: 15px;
      top: -4px;
      padding: 2px 6px 2px;
      border-radius: 12px;
      z-index: 1;
    }
    &-order {
      width: 32px;
      height: 32px;
      position: relative;
      margin-right: 24px;

      span {
        mask-image: url('@/assets/images/svg/user.svg');
        background-color: currentColor;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 32px;
        margin-right: 24px;
        transition: color .24s;
        width: 32px;
        height: 32px;
      }
      &:hover span {
        color: color(primary);
      }
      &:after {
        content: "";
        background: url("@/assets/images/useful/ischeckedWhite.svg") 50% 50%
          no-repeat;
        background-size: contain;
        width: 12px;
        height: 12px;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 24px;
        left: 24px;
        z-index: 1;
      }
      &:before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: color(red);
        position: absolute;
        transform: translate(-50%, -50%);
        top: 24px;
        left: 24px;
        z-index: 1;
      }
    }

    &-burger {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      margin-left: 24px;
      span {
        display: block;
        height: 2px;
        background: color(black);
        transition: background 0.24s;
        width: 30px;
        position: relative;

        &:before,
        &:after {
          content: "";
          left: 0;
          right: 0;
          height: 2px;
          background: color(black);
          position: absolute;
          transition: 0.24s;
          transition-property: transform, top, bottom, background;
        }

        &:before {
          top: -9px;
        }

        &:after {
          bottom: -9px;
        }
      }
      &.active {
        span {
          background: transparent;

          &:before {
            top: 0;
            transform: rotate(45deg);
          }

          &:after {
            bottom: 0;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
</style>
