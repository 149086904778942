<script setup lang="ts">
import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement)
const props = defineProps({
  speed: {},
  color: {},
  speedPercent: {},
  iconDownload: {},
})
const labels = ref([0, 5, 10, 50, 100, 250, 500, 750, 1000])
const shadow = ref()
const x = ref(154)
const y = ref(80)
const radius = ref(36)
const startAngle = ref(0.85 * Math.PI)
const endAngle = computed(() => (0.85 + (2.16 - 0.85) * props.speedPercent / 100) * Math.PI)

const getTickStyle = (index) => {
  const total = labels.value.length // Количество меток
  const angle = -120 + (index * 240) / (total - 1) // Угол для каждой метки
  return {
    transform: `rotate(${angle}deg) translate(0, -85px) rotate(${-angle}deg)`, // Расположение и поворот метки
  }
}

const chartData = computed(() => {
  return {
    labels: ['Speed', 'Remaining'],
    datasets: [
      {
        data: [props.speedPercent, 100 - props.speedPercent],
        backgroundColor: [props.color, '#363953'],
        borderWidth: 0,
      },
    ],
  }
})
const chartOptions = computed(() => {
  return {
    circumference: 240,
    rotation: -90,
    cutout: '82%',
    animation: {
      duration: 200,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  }
})
const drawArcWithShadow = (ctx, x, y, radius, startAngle, endAngle, color) => {
  const gradient = ctx.createRadialGradient(x, y, radius, x, y, 55)
  gradient.addColorStop(0, 'rgba(0, 0, 0, 0)')
  gradient.addColorStop(1, props.color)
  ctx.beginPath()
  ctx.arc(x, y, radius, startAngle, endAngle, false)
  ctx.lineWidth = 60
  ctx.strokeStyle = gradient
  ctx.shadowBlur = 5
  ctx.shadowColor = color
  ctx.shadowOffsetX = 0
  ctx.shadowOffsetY = 3
  ctx.stroke()
}
watchEffect(() => {
  const canvasContext = shadow.value?.getContext('2d')
  if (!canvasContext) return

  canvasContext.clearRect(0, 0, shadow.value.width, shadow.value.height)
  drawArcWithShadow(canvasContext, x.value, y.value, radius.value, startAngle.value, endAngle.value, props.color)
})
</script>

<template>
  <div class="speedometer d-flex flex-column">
    <canvas
      ref="shadow"
      class="speedometer__chart-shadow"
    />
    <div class="speedometer__chart-wrapper d-flex">
      <Doughnut
        class="speedometer__chart"
        :data="chartData"
        :options="chartOptions"
      />
      <div
        class="speedometer__chart-arrow"
        :style="`transform: rotate(${speedPercent * 2.5 - 125}deg);`"
      >
        <div
          class="speedometer__chart-arrowbg"
          :style="`background: ${color}`"
        />
      </div>
      <div class="speedometer__chart-legends">
        <span
          v-for="(label, index) in labels"
          :key="index"
          class="speedometer__chart-legend"
          :style="getTickStyle(index)"
        ><span
          :class="speed >= label ? 'white--text' : 'gray-inactive--text'"
          :style="`${index > Math.floor(labels?.length / 2) ? 'right: -9px' : index === Math.floor(labels?.length / 2) ? 'left: 50%; transform: translateX(-40%)' : ''}`"
        >{{ label }}</span></span>
      </div>
    </div>
    <div class="speedometer__text text-center">
      <p class="headline-1">
        {{ speed.toFixed(2) }}
      </p>
      <p class="title-5 d-flex align-center justify-center">
        <img
          v-if="iconDownload"
          class="speedometer__text-icon"
          src="/assets/images/svg/st_download.svg"
          alt="speedtest"
          style="margin-right: 4px;"
        ><img
          v-else
          class="speedometer__text-icon"
          src="/assets/images/svg/st_upload.svg"
          alt="speedtest"
          style="margin-right: 4px;"
        > Мбит/с
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.speedometer {
  pointer-events: none;
  &__chart {
    transform: rotate(-30deg);
    width: 230px !important;
    height: 230px !important;
    margin-left: auto;
    margin-right: auto;

    &-wrapper {
      position: relative;
      @media (max-width: getBreakpoint(mobile-lg)) {
        transform: scale(.8);
        margin-top: -24px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        transform: scale(.75);
        margin-top: -36px;
      }

    }
    &-shadow {
      position: absolute;
      left: calc(50% - 215px);
      width: 430px;
      height: 230px;
      opacity: .3;
    }

    &-arrow {
      width: 12px;
      height: 60px;
      position: absolute;
      bottom: 45%;
      left: 50%;
      transform-origin: bottom center;
      transform: rotate(-50deg);
      transition: transform .2s;
    }
    &-arrowbg {
      background-color: #4a90e2;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      transform: perspective(180px) rotateX(35deg) scaleY(120%);
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, .5) 100%);
      }
    }
    &-legends {
      position: absolute;
      left: calc(50% - 100px);
      width: 200px;
      height: 230px;
    }
    &-legend {
      position: absolute;
      top: 50%;
      left: 50%;
      transform-origin: center center;
      font-size: 14px;
      color: white; /* Цвет текста */
      text-align: center;
      span {
        position: absolute;
      }
    }
  }
  &__text {
    margin-top: -64px;
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: -8px;
    }
    &-icon {
      width: 20px;
      height: 20px;
      @media (max-width: getBreakpoint(mobile-lg)) {
        width: 18px;
        height: 18px;
      }
      @media (max-width: getBreakpoint(mobile-md)) {
        width: 16px;
        height: 16px;
      }
    }
  }

}
</style>
